<template>
  <card-container backButton closeButton>
    <div class="p-8 mt-16">
      <div class="text-3xl text-center">Personvern</div>
    </div>
  </card-container>
</template>
<script>
import CardContainer from '../components/CardContainer.vue'
export default {
  components: { CardContainer },
}
</script>
